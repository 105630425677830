
  
  export default function decimal(valor) {
      valor = Math.round(valor);
      total =  valor;
                        if (valor > 999) {
                                    let flo = parseFloat(valor / 1000);
                                    total = Math.floor(flo) * 1000;
                                    let res = flo.toFixed(1);
                                    let fra = res.toString().split('.');
  
                                                if (fra[1] > 0 && fra[1] < 6) {
                                                      total = total + 500;
                                                }
                                                if (fra[1] > 5) {
                                                      total = total + 1000;
                                                }
                        } else {
                                    total = valor;
                        }
                    
                  return total;
    }


  